import * as React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout, { LayoutProps } from "../default";
import SEO from "../../components/seo";

import "./style.scss";
export const query = graphql`
  query HomePageQuery($id: String) {
    mdx(id: { eq: $id }) {
      id

      fields {
        slug
      }

      exports {
        meta {
          title
          description
        }
        nav {
          title
        }
      }

      body
    }
  }
`;

export interface HomeLayoutProps extends LayoutProps {
  data: any;
  pageContext: { publishedDate: string; modifiedDate: string };
}

export default function HomeLayout({
  data: { mdx },
  pageContext: { publishedDate, modifiedDate }
}: HomeLayoutProps) {
  return (
    <Layout>
      {() => (
        <>
          <SEO
            title={mdx.exports.meta.title || undefined}
            description={mdx.exports.meta.description || undefined}
            modifiedDate={modifiedDate}
            publishedDate={publishedDate}
          />
          <div className="home markdown-content full-width">
            {mdx.exports.nav && mdx.exports.nav.title && (
              <h1>{mdx.exports.nav.title}</h1>
            )}
            {
              // @ts-ignore
              <MDXRenderer>{mdx.body}</MDXRenderer>
            }
          </div>
        </>
      )}
    </Layout>
  );
}
